$(document).ready(function(){

    // Initialize
    var addonDom = $('.logviewer');
    var cp       = addonDom.data('cp-path') ? addonDom.data('cp-path') : 'cp';
    var timezone = addonDom.data('timezone') ? addonDom.data('timezone') : 'America/Vancouver';

    // Only runs if addon is detected
    if (addonDom.length) {
        // Get logs data from controller
        var selectedValue, initValue; 
        var selectLoader = $('#log-selector .loading');
        var tableLoader  = $('.table-loader');
        var tableDom     = $('#log-table');

        // Create form select for dashboard report
        var logSelector  = $('<select name="log-list" id="log-list" class="form-control" />');
        var logs         = [];
        var logdata      = [];
        var logfiles     = getAPIData('/' + cp + '/addons/logviewer/logs/json');
        var limit        = 50;

        // Display loader animation while waiting
        selectLoader.show(30);    

        logfiles.success(function(data) {
            logs = data.logs;

            // Store to session storage for persistency during session
            //sessionStorage.setItem("logfiles", JSON.stringify(logs));

            $.each(logs, function(i, e){
                // Apply the limit
                if(i >= limit) return false;

                // Get the filename
                var logname = e.substr(19);

                // Get the date
                var logdate = e.substr(e.length - 14).slice(0, -4);
                
                // Append to select form
                logSelector.append($('<option />').val(logname).attr('rel', i).text('View log from ' + logdate));
            });

            // Populate report selector
            logSelector.appendTo('#log-selector');
        }).done(function(){

            // Hide select loader animation
            selectLoader.hide();

            // Sort based on index, in descending order
            var sortme = tinysort('#log-selector>select>option', {attr:'rel', order:'desc'});

            // Select the first item
            $('#log-list option:first-child').attr('selected', true);

            // Grab the first selected value
            initValue = $('#log-list option:first-child').val();

            // Display current selection log file onto a table
            displayLog(initValue);

        });

        // Display data on change
        logSelector.change(function(){
            // Get selected value
            selectedValue = this.value;
            tableLoader.show(30);
            displayLog(selectedValue);
        });

        $(tableDom).bootstrapTable({
            onPreBody: function() {
                //console.log('Body is being rendered...');
                // Display loader
                tableLoader.show(30);
            }
        });

        $(tableDom).bootstrapTable({
            onPostBody: function() {
                //console.log('Body has been rendered...');
                // Hide loader
                tableLoader.hide(30);
            }
        });

        $(tableDom).bootstrapTable({
            onLoadSuccess: function() {
                //console.log('Table has been rendered...');
                // Hide loader
                tableLoader.hide(30);
            }
        });
    }

    function displayLog(value) {
        // Grab log data
        logdata = getAPIData('/' + cp + '/addons/logviewer/log/' + value + '/json');
        //logUrl  = '/' + cp + '/addons/logviewer/log/' + value + '/json';

        // If no data, hide loader
        if ($.isEmptyObject(logdata)) {
            tableLoader.hide(30);    
        } else {
            //displayTable(logUrl);
            logdata.success(function(data) {
                displayTable(data);
            }).done(function(){
                tableLoader.hide();
            });
        }
    }

    /**
     * Display log data in a bootstrap table
     * @param  string tableDom [description]
     * @param  json array data [description]
     * @param  string timezone [description]
     * @return string          [description]
     */
    function displayTable(data) {

        if ($(tableDom).bootstrapTable('getSelections')) {
            $(tableDom).bootstrapTable('destroy');
        }

        var tableData = data.log;

        // Reports section
        var logOptions = tableOptions;
        logOptions.data = tableData;
        logOptions.sortName = 'date';
        logOptions.sortOrder = 'desc';
        logOptions.detailView = false;
        logOptions.columns = [{
            field: 'date',
            title: 'Date/Time',
            sortable: true,
            order: 'desc',
            class: 'col-md-3',
            formatter: niceDateFormatter
        }, {
            field: 'logger',
            title: 'Logger',
            class: 'col-md-1 text-center',
            sortable: true
        }, {
            field: 'level',
            title: 'Log Level',
            class: 'col-md-1 text-center',
            sortable: true,
            formatter: logLevelFormatter
        }, {
            field: 'message',
            title: 'Message',
            class: 'col-md-7 wrap',
            sortable: true
        }];

        $(tableDom).bootstrapTable(logOptions);
    }

    function successCalls() {
        //console.log('Table loaded successfully...');
        tableLoader.hide(30);
    }

    /**
     * Get Reports API data from controller
     * @return array
     */
    function getAPIData(url){
        return $.ajax({
            url: url
        });
    }

    // Show detailed info
    function detailView(value, row, index){
        var detailContainer = $('<div class="details" />');
        var defList = $('<dl class="definition-list" />');
        var detailedInfo = '<dt>Extra Info</dt><dd>' + row.extra + '</dd>';
        defList.append(detailedInfo);
        return detailContainer;
    }    

    // Custom Date sorter
    function dateSorter(a, b) {
        a = +a.substring(1); // remove $
        b = +b.substring(1);
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }

    // Display nice-looking date
    function niceDateFormatter(value, row, index){
        var input = moment(value);
        var prettyDate = input.tz(timezone).format("MMM DD, YYYY <br> H:mm a z", false);
        var sortDate = input.tz(timezone).format("YYYY-MM-DD HH:mm z", false);
        return sortDate;
    }

    // Format numbers
    function numberFormatter(value, row, index){
        return numeral(value).format('0,0');
    }

    // Format loglevel
    function logLevelFormatter(value, row, index){
        switch(value) {
            case 'DEBUG':
                return '<span class="loglevel debug">' + value + '</span>';
                break;
            case 'INFO':
                return '<span class="loglevel info">' + value + '</span>';
                break;
            case 'NOTICE':
                return '<span class="loglevel notice">' + value + '</span>';
                break;
            case 'WARNING':
                return '<span class="loglevel warning">' + value + '</span>';
                break;
            case 'ERROR':
                return '<span class="loglevel error">' + value + '</span>';
                break;
            case 'CRITICAL':
                return '<span class="loglevel critical">' + value + '</span>';
                break;
            case 'ALERT':
                return '<span class="loglevel alert">' + value + '</span>';
                break;
            case 'EMERGENCY':
                return '<span class="loglevel emergency">' + value + '</span>';
                break;
        }
    }

    // Default bootstrap table options
    var tableOptions = {
        pagination: true,
        search: true,
        showColumns: true,
        striped: true,
        showColumns: true,
        showToggle: true,
        showRefresh: true,
        detailFormatter: detailView,
        iconsPrefix: 'icon',
        icons: {
            paginationSwitchDown: 'icon-chevron-down',
            paginationSwitchUp: 'icon-chevron-up',
            refresh: 'icon-cw',
            toggle: 'icon-list',
            columns: 'icon-sweden',
            detailOpen: 'icon-circle-with-plus',
            detailClose: 'icon-circle-with-minus'
        }
    }
});
